import {
    FETCH_REQUESTED,
    FETCH_RESOLVED,
    FETCH_REJECTED,
    POST_REQUESTED,
    POST_RESOLVED,
    POST_REJECTED,
    DELETE_REQUESTED,
    DELETE_RESOLVED,
    DELETE_REJECTED,
    DELETE_AVATAR_REQUESTED,
    DELETE_AVATAR_RESOLVED,
    DELETE_AVATAR_REJECTED,
    CONNECT_REQUESTED,
    CONNECT_RESOLVED,
    CONNECT_REJECTED,
    DISCONNECT_REQUESTED,
    DISCONNECT_RESOLVED,
    DISCONNECT_REJECTED,
    REQUEST_CONFIRM_EMAIL_REQUESTED,
    REQUEST_CONFIRM_EMAIL_RESOLVED,
    REQUEST_CONFIRM_EMAIL_REJECTED,
    HANDLE_AP_FIELD_CHANGE
} from './actions';
import assign from 'lodash.assign';

const initialState = {
    data: {
        user: {
            facebookUserId: null,
            isFacebookUser: false
        },
        requestConfirmEmail: false
    },
    unsubscribedTopics: [],
    hasError: false,
    error: null,
    isLoading: false,
    isLoaded: false,
    isSaved: false,
    base64: {
        data: null,
        mime: null
    },
    fbExistingAccessToken: null //For native
};

export default (state = initialState, action) => {
    switch (action.type) {
        case FETCH_RESOLVED:
            return assign({}, state, {
                ...state,
                data: action.response,
                unsubscribedTopics: action.response.unsubscribedTopics,
                hasError: false,
                isLoading: false,
                isLoaded: true,
                error: null
            });
        case CONNECT_RESOLVED:
            return assign({}, state, {
                data: {
                    ...state.data,
                    user: {
                        ...state.data.user,
                        facebookUserId: action.response.facebookUserId,
                        fbAccessToken: action.response.fbAccessToken,
                        isFacebookUser: true
                    }
                },
                hasError: false,
                isLoading: false,
                isLoaded: true,
                error: null
            });
        case DISCONNECT_RESOLVED:
            return assign({}, state, {
                data: {
                    ...state.data,
                    user: {
                        ...state.data.user,
                        facebookUserId: null,
                        fbAccessToken: null,
                        isFacebookUser: false
                    }
                },
                hasError: false,
                isLoading: false,
                isLoaded: true,
                error: null
            });
        case DELETE_RESOLVED:
            return assign({}, state, {
                hasError: false,
                isLoading: false,
                error: null
            });
        case REQUEST_CONFIRM_EMAIL_RESOLVED:
            return assign({}, state, {
                data: {
                    ...state.data,
                    requestConfirmEmail: true
                },
                hasError: false,
                isLoading: false,
                isLoaded: true,
                error: null
            });
        case DELETE_AVATAR_RESOLVED:
            return assign({}, state, {
                data: {
                    ...state.data,
                    user: {
                        ...state.data.user,
                        userImages: []
                    }
                },
                base64: {
                    data: null,
                    mime: null
                },
                hasError: false,
                isLoading: false,
                error: null
            });
        case FETCH_REQUESTED:
        case DELETE_REQUESTED:
        case DELETE_AVATAR_REQUESTED:
        case CONNECT_REQUESTED:
        case DISCONNECT_REQUESTED:
        case REQUEST_CONFIRM_EMAIL_REQUESTED:
            return assign({}, state, {
                isLoading: true
            });
        case FETCH_REJECTED:
        case DELETE_AVATAR_REJECTED:
        case DELETE_REJECTED:
        case CONNECT_REJECTED:
        case DISCONNECT_REJECTED:
        case REQUEST_CONFIRM_EMAIL_REJECTED:
            return assign({}, state, {
                isLoading: false,
                hasError: true,
                error: action.message
            });
        case POST_REQUESTED:
            return assign({}, state, {
                isSaving: true,
                error: null
            })
        case POST_RESOLVED:
            return assign({}, state, {
                isSaving: false,
                data: {
                    ...action.response.athlete
                },
                base64: {
                    data: null,
                    mime: null
                },
                hasError: action.response.hasError,
                error: action.response.message
            })
        case POST_REJECTED:
            return assign({}, state, {
                isSaving: false,
                hasError: true,
                error: action.message
            })
        case HANDLE_AP_FIELD_CHANGE:
            var new_state = assign({}, state);
            new_state[action.key] = action.value;
            return new_state;
        default:
            return state;
    }
}